<template>
  <div id="home">
    <div class="video-container video-section-header">
      <video autoplay muted loop id="myVideo">
        <source :src="videoSrc" type="video/mp4" />
      </video>
      <div class="caption">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <h1 class="mb-4" v-html="$lang('header.title')" />
              <h4>
                {{ $lang("header.subtitle") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="py-8 bg-dark">
      <div class="container mb-8">
        <div class="row">
          <div class="col-lg-5 col-xl-4">
            <h3 class="fw-400">
              <span class="text-secondary">{{ $lang("learning.header") }}</span>
              {{ $lang("learning.subheader") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-1 offset-xl-2 pt-2">
            <h6>
              {{ $lang("learning.paragraph1") }}
            </h6>
            <h6>
              {{ $lang("learning.paragraph2") }}
            </h6>
          </div>
        </div>
      </div>

      <swiper
        :modules="modules"
        :pagination="{ clickable: false }"
        :breakpoints="{
          '0': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
        }"
        loop
        autoplay
        centeredSlides
        style="--pagination-bottom: 0px; padding-bottom: 40px"
      >
        <swiper-slide v-for="(n, i) of $lang('swiper_items')" :key="i">
          <ImageCaption :fileName="n.img">
            <p class="h6">{{ n.caption }}</p>
          </ImageCaption>
        </swiper-slide>
      </swiper>

      <div class="container mt-8">
        <Accreditations :textCentered="true" />
      </div>

      <div class="container-fluid mt-8 d-flex flex-column gap-4">
        <SectionCard
          :fileName="tab.image"
          v-for="(tab, ti) of $lang('tabs')"
          :key="ti"
        >
          <h1>{{ tab.title }}</h1>
          <h4 v-html="tab.subtitle" />

          <ul class="link-list">
            <li v-for="(link, li) of tab.links" :key="li">
              <router-link 
                class="h6" 
                :to="link.to"
              >
                {{ link.title }}
              </router-link>
            </li>
          </ul>
        </SectionCard>
      </div>
    </section>

    <section class="pt-4 pb-8 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h4 class="text-secondary mb-4">
              {{ $lang("testimonials.what_students_say") }}
            </h4>
            <h6 class="fw-300">
              {{ $lang("testimonials.paragraph") }}
            </h6>
          </div>
          <div class="col-lg-7 offset-lg-1">
            <swiper
              :modules="modules"
              :pagination="{ clickable: false }"
              navigation
              :slides-per-view="1"
              loop
              autoplay
              style="--pagination-bottom: 0px; padding-bottom: 20px"
            >
              <swiper-slide
                v-for="(n, i) of $lang('testimonial_items')"
                :key="i"
              >
                <div class="testimonial">
                  <div class="testimonial-header">
                    <h6 class="name">{{ n.name }}</h6>
                    <p class="fw-300 small mb-2">
                      <i>{{ n.date }}</i>
                    </p>
                    <div class="stars">
                      <i class="fa fa-star" />
                      <i class="fa fa-star" />
                      <i class="fa fa-star" />
                      <i class="fa fa-star" />
                      <i class="fa fa-star" />
                    </div>
                  </div>
                  <div class="testimonial-body">
                    <p class="small">
                      {{ n.body }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-secondary text-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("explore.header") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('explore.card1.header')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0">{{ $lang("explore.card1.body") }}</small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('explore.card2.header')"
              :to="{ name: 'our_program' }"
            >
              <small class="m-0">
                {{ $lang("explore.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3">
            <CardButton
              :title="$lang('explore.card3.header')"
              :to="{ name: 'student_life' }"
            >
              <small class="m-0">
                {{ $lang("explore.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-1">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>

    <CookieConsent />
  </div>
</template>

<script>
import SectionCard from "@/components/SectionCard.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import CardButton from "@/components/CardButton.vue";
import ImageCaption from "@/components/ImageCaption.vue";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";
import CookieConsent from "@/components/CookieConsent.vue";
import Accreditations from "@/components/Accreditations.vue";

export default {
  components: {
    SectionCard,
    ContactFormSection,
    ImageCaption,
    Swiper,
    SwiperSlide,
    CardButton,
    CookieConsent,
    Accreditations,
  },
  data: () => ({
    modules: [Pagination, Navigation, Autoplay],
    videoSrc: "/web-v2/mp4/background_home.mp4",
  }),
  mounted() {
    if (window.innerWidth >= 768) {
      this.videoSrc = "/web-v2/mp4/background_home_full.mp4";
      const myVideo = document.getElementById("myVideo");

      const playVideo = () => {
        myVideo.play();
        myVideo.removeEventListener("loadedmetadata", playVideo);
      };

      myVideo.addEventListener("loadedmetadata", playVideo);
    }
  },
};
</script>

<style>
</style>
